.footer-copyright {
  @include flex-center;
  background: linear-gradient(126.08deg, rgba(98, 113, 246, 0.1) 27.37%, rgba(249, 65, 122, 0.1) 100.36%);
  height: 50px;
  min-height: 50px;
  margin-top: auto;

  p {
    width: fit-content;
    background-color: #61198b;
    background: linear-gradient(90.7deg, rgba(98, 113, 246, 0.95) 2.5%, #F9417A 98.47%);
    background-size: 100%;
    background-repeat: repeat;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }
}