.form-property-benefits {
  &__lists {
    padding: 0 30px 0 30px;
    display: flex;
    flex-direction: column;
    ul {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    li {
      display: flex;
      flex-direction: row;
      margin-top: 30px;
      align-items: center;
      width: calc(100% / 2);
      margin-right: 10px;

      h3 {
        width: 150px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }

      p {
        font-size: 14px;
        line-height: 17px;
      }

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
  @media screen and (max-width: 1025px) {
    &__list {
      padding: 0 50px 0 50px;

      ul {
        display: flex;
        flex-direction: column;
      }

      li {
        display: flex;
        flex-direction: row;
        margin-top: 38px;
        align-items: center;
        width: 100%;

        p {
          font-size: 14px;
          line-height: 17px;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
}
