.pageSignup {
    height: 500px;
    background-color: aqua;
    .form {
        background-color: red;
    }
    .footer {
        .title {
            font-size: 20px;
            color: #ffff;
        }
        background-color: blue;
        width: 100%;
        height: 30px;
    }
}
