.validate-email {
  margin-top: 2em;

  h1 {
    font-weight: bold;
    font-size: 32px;
  }

  p {
    margin-top: 1em;
    padding: 0 1em;
  }

  span {
    color: #57157D;
    font-weight: 600;
  }

  button {
    margin-top: 2em;
    margin-bottom: 1.5em;
    display: block;
    background-color: #57157D;
    border: none;
    color: #ffffff;
    padding: 0.5em 1em;

    @media (max-width: $tablet) {
      margin: 2em auto 1.5em auto;
    }
  }

  a {
    color: #D82067;
    font-weight: 600;

    &:hover {
      text-decoration: underline;
    }
  }
}