.form-reference_form {
  padding: 30px;

  h1 {
    font-size: 24px;
    line-height: 29px;
    color: #d82067;
    margin-bottom: 50px;
    margin-top: 37px;
  }

  form {
    div {
      margin-bottom: 30px;
    }
  }

  &__check_terms {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: x-small;
    max-width: 100%;
    margin-bottom: 1em;
    margin-left: 1em;

    label {
      margin-left: 1em;
    }

    a {
      font-style: italic;
      font-weight: bold;
      color: #65137d;
    }
  }

  &_input__icon {
    margin-left: -1em;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 4px 61px rgba(190, 186, 186, 0.3);
    border-radius: 100px;
    width: fit-content;
    padding: 1em 2em;
    color: #b3b3b3;
    margin-bottom: 1em;

    &:focus-within {
      border: 1px solid #d82067;
      color: #d82067;

      input {
        color: #d82067;

        &::placeholder {
          color: #d82067;
        }
      }
    }

    input {
      border: none;
      color: #b3b3b3;
    }

    svg {
      margin-right: 0.5em;
    }
  }

  button[type="submit"] {
    display: block;
    background: linear-gradient(
      180deg,
      #57157d 0%,
      #7923aa 99.99%,
      rgba(121, 35, 170, 0) 100%
    );
    filter: drop-shadow(0px 4px 20px rgba(187, 98, 238, 0.38));
    border-radius: 100px;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    height: 40px;
    padding: 0 3em;
    outline: none;
    border: none;
  }
}
