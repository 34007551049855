.profile-menu {
  @include flex-center;
  cursor: pointer;

  &-data {
    display: flex;
    align-items: center;
    height: 60px;

    img,
    svg {
      width: 40px;
      margin-right: .7em;
    }
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    max-height: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000008A;
    padding: 0;
    min-width: 170px;
    z-index: 1;
    right: 0;
    transition: all .5s ease-in-out;
    background: url('../../../assets/icons/Rectangle.svg') no-repeat;
    background-color: #ffffff;
    background-size: 70%;
    background-position: 130% 50px;

    //top: calc(100% + .8em);
    // a:first-child {
    //   @include is-disabled;
    // }
  }

  .dropdown-content a {
    display: flex;
    color: #000000;
    padding: .5em 16px;
    text-decoration: none;
    transition: all .5s ease-in-out;

    svg {
      font-size: larger;
      margin-right: .5em;
    }
  }

  .dropdown-content a:hover {
    background-color: #57157D;
    color: #ffffff;
    font-weight: normal;
  }

  .dropdown:hover .dropdown-content {
    display: block;
    max-height: 400px;
    padding: 1em 0;
    height: auto;
  }

}