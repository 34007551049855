//Any generic styles for the site. Shouldn't have any classes or ids for selectors.

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  width: 100%;
  height: 100%;
}

body {
  height: 100%;
  font-family: $font;
  box-sizing: border-box;
}

#root {
  overflow: auto;
  height: 100vh;

  >div {
    height: 100%;
    background-color: $background-color;
  }
}

img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

select,
option,
input[type="radio"] {
  cursor: pointer;
}

// select {
//   background: url('data:image/svg+xml;utf8,<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L6 6L11 1" stroke="black" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat 95% 50%;
//   -moz-appearance: none;
//   -webkit-appearance: none;
//   appearance: none;
// }

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;

  &:hover {
    color: #57157D;
  }
}

h1,
h2 {
  font-weight: bold;
}

//cookies crm
#myConsent {
  >div {
    background-color: #FC176F;

    a {
      background: linear-gradient(180deg, #57157D 0%, #7923AA 99.99%, rgba(121, 35, 170, 0) 100%) !important;
      box-shadow: 0px 4px 20px rgba(187, 98, 238, 0.38);
      color: #FFFFFF !important;
      padding: .5em 1em !important;
      border-radius: 10% !important;
      margin: .5em 0;
    }
  }
}