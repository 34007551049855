form {

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.react-datepicker__header,
.react-datepicker__day--selected {
  background-color: #61198b !important;
}

.react-datepicker__day-name,
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #ffffff !important;
}