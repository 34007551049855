.form-speciality {
  .list-specialities {
    margin-bottom: 2em;

    .speciality-card {
      display: flex;
      align-items: center;
      background: #ffffff;
      border-color: #CCCCCC;
      border-width: 1px;
      border-radius: 9px;
      border-style: solid;
      height: 60px;
      margin: auto;
      padding: 0 1em;
      margin-top: 1em;
      cursor: pointer;
      transition: all .5s ease-in;

      &-data {
        display: flex;
        width: 90%;
        justify-content: space-between;
        align-items: center;
      }

      &-category {
        width: 260px;

        p:first-child {
          font-weight: 600;
        }

        @include text-ellipsis;
      }

      &-warranty {}

      &-description {
        width: 220px;
        @include text-ellipsis;
      }

      >svg {
        margin-left: auto;
        margin-right: .5em;

        &:hover {
          color: #D82067;
        }
      }

      &-warranty span {
        font-weight: 600;
        font-size: larger;
        color: #61198b;
        display: block;
        text-align: center;
      }

      &:hover {
        background: darken(#ffffff, 5);
      }

      div {
        margin-right: 1em;
      }
    }
  }

  button[type="submit"] {
    margin-top: 0;
  }

  button[type="button"] {
    @extend .btn-submit;

    &:disabled {
      @include is-disabled;
    }
  }
}