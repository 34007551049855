.footer {
  background: linear-gradient(
    126.08deg,
    rgba(98, 113, 246, 0.1) 27.37%,
    rgba(249, 65, 122, 0.1) 100.36%
  );
  height: 50px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
  padding-right: 10px;

  &__text {
    p {
      width: fit-content;
      background-color: #61198b;
      background: linear-gradient(
        90.7deg,
        rgba(98, 113, 246, 0.95) 2.5%,
        #f9417a 98.47%
      );
      background-size: 100%;
      background-repeat: repeat;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
    }
  }

  &__icons {
    img {
      width: 32px;
      height: 32px;
      margin-right: 20px;
    }
  }

  @media screen and (max-width: 770px) {
    flex-direction: column;
    height: 80px;
    min-height: 80px;
    justify-content: center;
    padding: 0;

    &__text {
      margin-bottom: 6px;
    }
  }
}
