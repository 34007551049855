.modal-dialog {
  background: #ffffff;
  border: 1px solid #CCCCCC;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  padding: 2em;

  h3 {
    font-size: 2em;
    font-weight: 600;

  }

  p {
    font-size: medium;
    margin: 1em auto 2em;
  }

  >div {
    display: flex;
    justify-content: space-around;

    >button {
      @include flex-center;
      height: 40px;
      min-width: 120px;
      text-transform: uppercase;
      font-weight: 600;
    }

    .btn {
      &-cancel {
        background: transparent;
        border: 1px solid #FC176F;
        color: #FC176F;
      }

      &-accept {
        color: #ffffff;
        border: none;
        background: linear-gradient(180deg, #57157D 0%, #7923AA 99.99%, rgba(121, 35, 170, 0) 100%);
        filter: drop-shadow(0px 4px 20px rgba(187, 98, 238, 0.38));
      }
    }
  }
}