.step-tabs {
  display: flex;
  max-width: 760px;
  margin: 0 auto;
  text-align: center;
  justify-content: space-around;
  position: relative;

  >div {
    background: #fff;
    z-index: 1;
  }

  &:before {
    content: '';
    position: absolute;
    width: 80%;
    margin: auto;
    top: 40%;
    height: 1px;
    background: #000000;
  }


  .step-tab {
    @include flex-center;
    flex-direction: column;
    padding: 1em 2em;
    cursor: default;
    transition: all .5s ease;

    &-icon {
      @include flex-center;
      border: 1px solid #000000;
      border-radius: 50%;
      width: 70px;
      height: 70px;
      margin-bottom: 1em;
    }

    h2 {
      font-size: x-large;
    }

    &-activate {
      .step-tab-icon {
        border-color: #61198b;

        path {
          fill: #61198b;
        }
      }

      h2 {
        color: #61198b;
      }

    }
  }

}

@media screen and (max-width: $medium) {
  .step-tabs {
    flex-direction: column;
    width: 100%;
  }
}