.informative-detail {
  .informative-sub-title {
    padding: 20px 0px 10px;
    font-weight: bold;

    span {
      padding-bottom: .2em;
      border-bottom: 1px solid #FC176F;
    }
  }
  .informative-content-detail {
    display: flex;
    width: 100%;
    padding: $padding__30 $padding__30 $padding__30;
    justify-content: space-evenly;
    text-align: center;


    .informative-slopes {
      @include flex-center;
      flex-direction: column;
      background: #FEC8CB;
      width: 219px;
      height: 90px;
      border-radius: 5px;
      color: #6B6B6B;
    }
  
    .informative-active {
      @include flex-center;
      flex-direction: column;
      background: #B6FFFD;
      width: 219px;
      height: 90px;
      border-radius: 5px;
      color: #6B6B6B;
    }
  
    .informative-closed {
      @include flex-center;
      flex-direction: column;
      background: #FCFFC7;
      width: 219px;
      height: 90px;
      border-radius: 5px;
      color: #6B6B6B;
    }
  
    .informative-services {
      @include flex-center;
      flex-direction: column;
      background: #BCFFD8;
      width: 219px;
      height: 90px;
      border-radius: 5px;
      color: #D8424A;
    }
  
    .informative-quantity {
      font-weight: bold;
      font-size: 36px;
      margin-bottom: .5rem;
      transition: all .5s ease;
    }
  }
  @media screen and (max-width: $medium) {
    .informative-content-detail {
      flex-direction: column;
      height: 500px;
    }
  }
} 

