.form {

  &-row,
  &-column {
    display: flex;
    justify-content: space-between;

    >div {
      position: relative;
      margin-bottom: 2em;
    }
  }

  &-column {
    flex-direction: column;
  }

  &-item {
    @include flex-center;
    margin-left: -1em;
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid transparent;
    box-shadow: 0px 4px 61px rgba(190, 186, 186, 0.3);
    border-radius: 100px;
    width: fit-content;
    padding: 1em 2em;
    color: #B3B3B3;

    &:focus-within {
      border: 1px solid #57157D;
      color: #57157D;

      select {

        &::placeholder {
          color: #57157D;
        }
      }

      @include focus-anim-shadow;
    }

    select,
    input {
      width: 100%;
      @include text-ellipsis;
    }

    select {
      border: none;
      color: #B3B3B3;
      background: transparent;
    }

    input {
      border: none;
      color: #B3B3B3;
    }

    svg {
      margin-right: .8em;
    }

    &-text-area {
      background: #FFFFFF;
      box-shadow: 0px 4px 61px rgba(190, 186, 186, 0.3);
      border-radius: 10px;

      textarea {
        border: none;
        resize: none;
        color: #B3B3B3;
        background: transparent;
      }
    }

    &-checkbox {
      box-shadow: none;
      padding-left: 1em;
      width: 360px;

      &:focus-within {
        border: none;
        color: #57157D;
        @include focus-anim-shadow;
      }

      label {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      input[type="checkbox"] {
        width: 1em;
        height: 1em;
      }
    }
  }

  &-item-center {
    @include flex-center;
    // margin-left: -1em;
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid transparent;
    box-shadow: 0px 4px 61px rgba(190, 186, 186, 0.5);
    border-radius: 100px;
    // width: 80%;
    padding: 1em 2em;
    color: #B3B3B3;

    &:focus-within {
      border: 1px solid #57157D;
      color: #57157D;

      select {

        &::placeholder {
          color: #57157D;
        }
      }

      @include focus-anim-shadow;
    }

    select,
    input {
      width: 100%;
      @include text-ellipsis;
    }

    select {
      border: none;
      color: #B3B3B3;
      background: transparent;
    }

    input {
      border: none;
      color: #B3B3B3;
    }

    svg {
      margin-right: .8em;
    }

    &-text-area {
      background: #FFFFFF;
      box-shadow: 0px 4px 61px rgba(190, 186, 186, 0.3);
      border-radius: 10px;

      textarea {
        border: none;
        width: 100%;
        resize: none;
        color: #B3B3B3;
        background: transparent;
      }
    }

    &-checkbox {
      box-shadow: none;
      padding-left: 1em;
      width: 360px;

      &:focus-within {
        border: none;
        color: #57157D;
        @include focus-anim-shadow;
      }

      label {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      input[type="checkbox"] {
        width: 1em;
        height: 1em;
      }
    }
  }


  &-error-msg {
    display: block;
    color: #D82067;
    margin-top: 3px;
    position: absolute;
  }

  &-error-input {
    .form-item {
      border: 1px solid #D82067;
      color: #D82067;

      &-checkbox {
        border: none;
      }
    }
  }
}