.profile-user {
  max-width: 1240px;
  margin: auto;
  margin-bottom: 5em;

  &-header {
    margin-top: 3em;
    display: flex;

    &-portrait {
      margin-right: 2em;

      img,
      svg {
        width: 120px;
        height: 120px;
      }
    }

    &-data {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    &-name {
      font-size: x-large;
    }

    &-email {
      margin: .5em 0;
      font-size: larger;
    }

    &-city {}

  }

  &-content {
    &-item {
      &-title {
        max-width: 60%;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 2em;
        padding: 1em 0;

        svg {
          margin-right: 16px;
          font-size: 1.5em;
          color: #61198B;
        }

        >div {
          display: none;
          transition: all 1s ease;
          margin-left: auto;

          svg {
            font-size: 1em;
          }
        }

        &:hover {
          color: #61198B;

          >div {
            display: flex;
          }
        }
      }

      &-data {
        >div {
          cursor: pointer;
          margin: 1em;
        }
      }
    }
  }

  .form {
    &-speciality {
      max-width: 960px;
      margin: auto;
    }

    &-item {
      max-width: 360px;
    }
  }

  .btn-submit {
    display: block;
    background: linear-gradient(180deg, #57157D 0%, #7923AA 99.99%, rgba(121, 35, 170, 0) 100%);
    filter: drop-shadow(0px 4px 20px rgba(187, 98, 238, 0.38));
    border-radius: 100px;
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    height: 40px;
    padding: 0 3em;
    outline: none;
    border: none;
    margin: auto;
    letter-spacing: 1px;
  }

  button[type="submit"] {
    margin-top: 3em;
    @extend .btn-submit;
  }
}