.recover-password {
  &-container {
    height: calc(100vh - 50px);
    @include flex-center;
    background: url('../../../../assets/icons/Triangle.svg') -5% -20% no-repeat,
      url('../../../../assets/icons/Rectangle.svg') center 0 no-repeat;
    background-color: #ffffff;
    background-size: 20%, 50%;

    form {
      >div {
        margin-bottom: 2em;
      }

      button {
        height: 40px;
        padding: 0 2em;
        background: linear-gradient(180deg, #57157D 0%, #7923AA 99.99%, rgba(121, 35, 170, 0) 100%);
        filter: drop-shadow(0px 4px 20px rgba(187, 98, 238, 0.38));
        border-radius: 100px;
        color: #ffffff;
        margin: 0 auto;
        text-transform: uppercase;
      }
    }
  }

  @media (max-width: $mobile) {
    &-container {
      background-position: -40% -20%, 110% 120%;
      background-size: 60%, 80%;
    }
  }
}