.form-step {

  padding: 80px;
  align-items: center;

  &-header {
    text-align: center;

    img {
      height: 3em;
    }

    >div {
      font-size: 1.2em;
    }

    margin-bottom: 3em;
  }

  &-quest {
    display: flex;
    margin-top: 20px;
    height: 300px;
    align-items: center;
    margin: auto;
    max-width: 800px;

    &-img {
      display: flex;
      height: 300px;
      width: 50%;

      img {
        margin-left: auto;
        margin-right: 2em;
      }
    }

    input {
      max-width: 300px;
    }
  }

  &-actions {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 3em;

    &-responsive {
      display: none
    }

    >div {
      max-width: 500px;
      width: 70%;
      height: fit-content;
    }

    button {
      cursor: pointer;
      display: block;
      background-color: #57157D;

      color: #fff;
      border: none;
      text-align: justify;
      padding: .5em 1em;
      border-radius: 15px;
      transition: all .5s ease-in-out;


      &:disabled {
        cursor: not-allowed;
        opacity: 0.3;
        background-color: #d1d1d1;
        color: #333;

        &:hover {
          background-color: #d1d1d1;
          color: #333;
        }
      }

      &.selected {
        background-color: #57157D;
        color: #fff;
      }
    }
  }

  .animate__animated {
    width: 50%;

    >div {
      display: flex;
      flex-direction: column;
    }

    .form-error-msg {
      position: relative;
      font-size: smaller;
    }

    label {
      margin-top: 1em;
    }

    input {
      margin-top: .5em;
      border-radius: 15px;
      padding: .7em 1em;
      border: none;
      background-color: #d1d1d1;
      color: #333;
    }

    button {
      display: block;
      background-color: #d1d1d1;
      color: #333;
      border: none;
      text-align: justify;
      padding: .5em 1em;
      border-radius: 15px;
      margin-top: 1em;
      transition: all .5s ease-in-out;
      max-width: 250px;

      &:hover {
        background-color: #57157D;
        color: #fff;
      }

      &.selected {
        background-color: #57157D;
        color: #fff;
      }
    }
  }

  @media screen and (max-width: $medium) {
    padding: 2em 2em;

    &-header {
      margin-bottom: 1em;

      img {
        display: none;
      }

      h1,
      h2 {
        font-size: 1.2em;
      }
    }

    &-quest {
      flex-direction: column;
      height: auto;

      &-img {
        margin-bottom: 1em;
        height: auto;
      }
    }

    &-actions {
      margin-top: auto;
      position: absolute;
      bottom: 0;
      left: 10%;
      right: 10%;
      display: none;

      &-responsive {
        display: block;

        >div {
          max-width: 100%;
          width: 100%;
        }

        &-btns {
          display: flex;
          justify-content: space-between;
          margin: .5em 0 1em;
        }
      }
    }

    .form-step-quest-img {
      width: 200px;
    }

    .animate__animated {
      width: 100%;
    }
  }
}