.login_form {
  margin-left: 1em;

  &_welcome {
    font-size: 25px;
    margin-bottom: 1em;

    strong {
      color: #65137D;
    }

    h1 {
      margin-top: 8px;
      margin-bottom: .5em;
      font-size: 36px;
      color: #888888;
      font-weight: bold;
    }
  }

  &_input__icon {
    @include flex-center;
    margin-left: -1em;
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 61px rgba(190, 186, 186, 0.3);
    border-radius: 100px;
    width: fit-content;
    padding: 1em 2em;
    color: #B3B3B3;
    margin-bottom: 1em;

    &:focus-within {
      border: 1px solid #D82067;
      color: #D82067;

      input {
        color: #D82067;

        &::placeholder {
          color: #D82067;
        }
      }
    }

    input {
      border: none;
      color: #B3B3B3;

      &::placeholder {}
    }

    svg {
      margin-right: .5em;
    }
  }

  &__errors {}

  &__forgot_pass {
    text-align: right;
    display: inline-block;
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #65137D;
    margin: 1em 0;

    &:hover {
      text-decoration: underline;
    }
  }

  &__check_terms {
    @include flex-center;
    font-size: x-small;
    max-width: 260px;
    margin-bottom: 2em;

    label {
      margin-left: 1em;
    }

    a {
      font-style: italic;
      font-weight: bold;
      color: #65137D;
    }
  }

  button[type="submit"] {
    display: block;
    background: linear-gradient(180deg, #57157D 0%, #7923AA 99.99%, rgba(121, 35, 170, 0) 100%);
    filter: drop-shadow(0px 4px 20px rgba(187, 98, 238, 0.38));
    border-radius: 100px;
    text-transform: uppercase;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    height: 40px;
    padding: 0 3em;
    outline: none;
    border: none;

  }

  form>div {
    margin-bottom: 2em;
  }
}