$padding__20: 20px;
$padding__30: 30px;


.invitation {
  background: #FFFFFF;
  height: 100%;

  .invitation-container {
    background: #FFFFFF;
    margin: 0 none;

    .invitation-content {
      padding: $padding__30;

      .invitation-component {
        .invitation-title {
          font-weight: bold;
          font-size: 32px;
        }
      }

      .invitation-body {
        display: flex;
        height: 100%;
        width: 100%;

        .invitation-flex-column {
          width: calc(100%/2);
          height: 450px;

          .invitation-flex-content {
            margin: 30px;

            .invitation-flex-title {
              font-weight: bold;
              font-size: 18px;
              color: black;

              span {
                padding-bottom: .2em;
                border-bottom: 1px solid #FC176F;
              }
            }

            .invitation-flex-list {

              >h2 {
                margin-top: 3em;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                color: #A1ACB4;
              }
            }
          }
        }

        .invitation-flex-row {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          color: #a2bdd1;
          font-weight: bold;
          font-size: 13px;
          font-family: Montserrat;

          &.mt-4 {
            margin-top: 4.5em;
          }

          .reload-btn {
            cursor: pointer;

            svg {
              transition: all .2s ease-in-out;
            }

            svg:hover {
              color: #333;
            }
          }

          div {
            margin-right: 1em;
          }

          div:first-child {
            width: 90px;
            margin-right: 2em;
            margin-left: 1em;
          }

          div:nth-child(2) {
            flex: 2;
          }

          div:nth-child(3) {
            flex: 4;
          }
        }

        .invitation-flex-card {
          display: flex;
          align-items: center;
          border-color: #CCCCCC;
          border-width: 1px;
          border-radius: 9px;
          border-style: solid;
          height: 60px;
          margin: auto;
          padding: 0 1em;
          margin-top: 1em;
          cursor: pointer;
          transition: all .5s ease-in;

          &:hover {
            background: darken(#ffffff, 5);
          }

          div {
            margin-right: 1em;
          }

          div:first-child {
            width: 90px;
          }

          div:nth-child(2) {
            text-transform: capitalize;
            flex: 2;
          }

          div:nth-child(3) {
            text-transform: capitalize;
            flex: 3;
            @include text-ellipsis;
          }

          .invitation-flex-btn_acepte {
            text-transform: uppercase;
            background: #FC176F;
            border: 3px solid #FC176F;
            box-shadow: 0px 4px 20px rgba(187, 98, 238, 0.38);
            color: white;
            border-radius: 15px;
            font-size: smaller;
            height: 27px;
            width: 99px;
          }
        }


        .invitation-flex-request {
          width: calc(100%/2);
          height: 450px;

          .invitation-flex-content {
            margin: 30px;

            .invitation-flex-title {
              font-weight: bold;
              font-size: 18px;
              color: black;

              span {
                padding-bottom: .2em;
                border-bottom: 1px solid #FC176F;
              }
            }

            .invitation-flex-status {
              font-weight: bold;
              color: #A1ACB4;
              display: flex;
              justify-content: flex-start;
              padding: 20px 0px 0px;

              >div {
                margin-right: 5em;
                transition: all .5s ease;
                cursor: pointer;
              }

              .tab-active {
                color: #000000;
              }
            }

            .invitation-flex-row {
              display: flex;
              justify-content: space-around;
              color: #a2bdd1;
              font-weight: bold;
              font-size: 13px;
              padding: 20px 0px 0px;
              font-family: Montserrat;
            }

            .invitation-flex-square {
              margin-top: 2em;
              font-size: 14px;
              border-color: #CCCCCC;
              border-width: 1px;
              border-radius: 5px;
              border-style: solid;
              height: 340px;
              text-align: center;

              .invitation-flex-text__title {
                font-weight: bold;
                font-size: 24px;
                padding: 130px 0px 0px;
              }

              .invitation-flex-text {
                padding: 20px 50px 0px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $medium) {

    .invitation-container {
      flex-direction: column;
      width: 100%;

      .invtitaion-content-detail {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .invitation-content {
        width: 100%;

        .invitation-body {
          flex-direction: column;
          width: 100%;

          .invitation-flex-column {
            width: 100%;
            height: 700px;
          }

          .invitation-flex-request {
            width: 100%;
            height: 500px;
          }
        }
      }
    }
  }
}