.property {
  background: #ffff;
  .container {
    max-width: $max-width-wrapper; 
    margin: 0 none; 
    background: #ffff;
    .body {
      .body__search {
        .body__text {
          margin: 30px;
        }
        .body__group__filter {
          margin: 30px;
          display: flex;
          justify-content: space-between;
          .body__icons {
            display: flex;
            justify-content: space-between;
            width: 5%;
          }
        } 
      }
      .body__list__properties {
        display: flex;
        flex-wrap: nowrap;
        .body__list {
          background: #FFF;
          width: 216px;
          margin: 10px;
          //height: 228px;
          height: 240px;
          text-align: center;
          line-height: 75px;
          font-size: 30px;
          
          .body__property {
            height: 139px;
          }
          .body__detail_property {
            padding: 0;
            margin: 0;
            width: 100%;
            height: 80px;
            .body__row {
              display: flex;
              justify-content: space-between;
              margin: 4px;
              height: 20px;
              align-items: center;
              .body__name_property {
                font-size: 11px;
                font-weight:bold;
                color: #BBBBBB;
              }
            }
            .body__price {
              display: flex;
              margin: 4px;
              height: 20px;
              align-items: center;
              .body__detail__price {
                font-size: 14px;
                font-weight:bold;
              }
            }
            .body__location {
              display: flex;
              margin: 4px;
              height: 12px;
              align-items: center;
              .body__location-icon {
                font-size: 9px;
              }
              .body__location {
                font-size: 9px;
              }
            }
            .body__list-amenities {
              display: flex;
              justify-content: space-around;
              margin: 4px;
              height: 30px;
              align-items: center;
              .content {
                display: flex;
                height: 30px;
                width: 250px;
                justify-content: space-around;
                align-items: center;
                
                .body__amenity {
                  background: #FAE2EB;
                  display: flex;
                  height: 20px;
                  font-size: 12px;
                  border-radius: 3px;
                  justify-content: space-around;
                  align-items: center;
                  color: #D82067;
                }

              }
              
            }
          }
        }
      }
     }
  }
}
