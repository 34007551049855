.modal {
  z-index: 1000;
  //align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include fade-in;
  @include flex-center;

  &-bkg {
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000 0% 0% no-repeat padding-box;
    opacity: .5;
    backdrop-filter: blur(19px);
    -webkit-backdrop-filter: blur(19px);
  }

  &-content {
    max-width: 1440px;
    height: fit-content;
    z-index: 0;
  }
}