.feedback {
  z-index: 1000;
  cursor: auto;
  //align-items: center;
  position: fixed;
  top: 70px;
  right: 50px;
  background: #703891;
  padding: 1em 2em;
  @include fade-in;
  @include flex-center;
  box-shadow: 0px 4px 61px rgba(190, 186, 186, 0.5);

  &-close {
    cursor: pointer;
    position: absolute;
    top: 1em;
    right: 1em;
    color: #ffffff;
  }

  &-content {
    color: #ffffff;
    min-width: 320px;

    h2 {
      margin-bottom: .5em;
    }
  }
}