.download-page {
  display: flex;
  flex-direction: column;
  background: linear-gradient(126.08deg, rgba(98, 113, 246, 0.1) 27.37%, rgba(249, 65, 122, 0.1) 100.36%),
    url('../../../../../assets/login-bkg.png');

  header {
    height: 60px;
    padding: 1em;
  }

  &-content {
    height: calc(100vh - 110px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    &-text {
      margin-left: 5em;

      h2 {
        font-size: 2em;
      }

      p {
        font-size: 1.5em;
      }
    }

    &-actions {
      margin-left: 5em;
      margin-top: 2em;
      display: flex;

      a {
        display: block;
        width: 280px;
        @include flex-center;
      }
    }
  }

  @media screen and (max-width: $tablet) {
    &-content {

      &-text,
      &-actions {
        margin-left: 2em;
      }

      &-actions {
        flex-direction: column;
      }
    }
  }
}