$height: calc(100vh - 110px);
$margin: auto;
//Colors//
$color_pink: #D82067;
$color_grey: #DFDFDF;
$color_black: #000000;
$color_text: #65137D;
$color_button:#57157D;

//Wrapper
$max-width-wrapper: 1440px;

.login {
  background: linear-gradient(126.08deg, rgba(98, 113, 246, 0.1) 27.37%, rgba(249, 65, 122, 0.1) 100.36%), url('../../../../assets/login-bkg.png');
  display: flex;
  flex-direction: column;

  .login-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .login-signup {
      background-color: #61198B;
      width: calc(100%/2);
      color: #DFDFDF;
      height: $height;

      .login-sign {
        margin: 180px 70px auto;
        justify-content: space-between;
        flex-direction: column;
        display: flex;
      }

      .login-sign-user {
        font-size: 25px;
        text-align: right;
        font-weight: bold;
        margin: auto 0;
        font-size: 34px;
        line-height: 1em;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      .login-sign-yampi {
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 30px;
        text-align: right;
        color: #FFEFEF;
        margin-top: 1em;
      }

      .login-sign-button {
        max-width: 200px;
        display: block;
        text-transform: uppercase;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: bold;
        height: 40px;
        padding: 0 3em;
        outline: none;
        border: none;
        margin-left: auto;
        background: #FC176F;
        box-shadow: 0px 4px 20px rgba(187, 98, 238, 0.38);
        border-radius: 100px;
        margin-top: 2em;
      }
    }

    .login-row {
      width: calc(100%/2);
      height: $height;
      padding: 0px;
      margin: 0px;
      align-items: center;
      justify-content: center;
      display: flex;

      .login-column {
        width: 90%;
        @include flex-center;
        height: 100%;
        justify-content: flex-start;
        margin: auto;
      }
    }

    .login-register {
      background-color: transparent;

      .login-sign-user {
        color: #65137D;
      }

      .login-sign-yampi {
        font-style: normal;
        font-weight: normal;
        font-size: 25px;
        line-height: 30px;
        text-align: right;
        color: #000000;
        margin-top: 1em;

        span {
          color: #65137D;
          font-weight: bolder;
        }
      }
    }
  }

  @media screen and (max-width: $medium) {
    //height: inherit;

    .login-content {
      //flex-direction: column;
      width: 100%;
      height: auto;

      .login-signup {
        display: none;
      }

      .login-row {
        flex-direction: column;
        margin-top: 3em;
        width: 100%;
      }
    }
  }
}