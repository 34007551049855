.specialist {
  margin-top: 2em;

  &-header {
    p {
      font-size: smaller;
      margin-bottom: .5em;
    }

    h2 {
      font-size: x-large;
      font-weight: 900;
    }

    margin-bottom: 2em;
  }

  &-container {
    max-width: 1220px;
    margin: auto;
    margin-top: 2em;

    &-form {
      .form-container {
        margin-top: 5em;
        margin-bottom: 2em;

        &-wrapper {
          max-width: 860px;
          margin: auto;
        }

        select,
        label,
        input {
          width: 260px;
        }
      }
    }

    .btn-submit {
      display: block;
      background: linear-gradient(180deg, #57157D 0%, #7923AA 99.99%, rgba(121, 35, 170, 0) 100%);
      filter: drop-shadow(0px 4px 20px rgba(187, 98, 238, 0.38));
      border-radius: 100px;
      text-transform: uppercase;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: bold;
      height: 40px;
      padding: 0 3em;
      outline: none;
      border: none;
      margin: auto;
      letter-spacing: 1px;
    }

    button[type="submit"] {
      margin-top: 3em;
      @extend .btn-submit;
    }
  }

  @media screen and (max-width: $medium) {
    .form-container {
      .form-container-wrapper {
        .form-row {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}