.invitation-detail {
  background: #ffffff;
  border: 1px solid #CCCCCC;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  padding: 2em 3em;
  min-width: 70vw;
  max-width: 90vw;
  min-height: 70vh;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .row {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &-header {
    margin-bottom: 3em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 2.5em;
      font-weight: 600;
    }
  }

  h4 {
    font-size: larger;
    font-weight: 600;
  }

  &-state {
    @include flex-center;
    flex-direction: column;
    cursor: pointer;
    margin-right: 2em;

    p {
      margin-top: .5em;
      padding: 1em 2em;
      width: fit-content;
      font-weight: bold;
      text-transform: uppercase;
    }

    .open {
      background: #FEC8CB;
      border-radius: 5px;
      color: #6B6B6B;
    }

    .accepted {
      background: #B6FFFD;
      border-radius: 5px;
      color: #6B6B6B;
    }
  }

  &-body {

    >h4 {
      font-size: large;
    }
  }

  &-description {
    margin-top: 1em;
    margin-bottom: 2em;

    >h4 {
      font-size: x-large;
    }

    p {
      //height: 5em;
      margin-top: .5em;
      text-transform: lowercase;
      background-color: #efefef;
      min-width: 60%;
      max-width: 90%;
      min-height: 5em;
      padding: 1em;
      border-radius: 10px;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  //FormticketCost
  .form-price {
    margin: 1em 0 2em;

    h3 {
      margin-top: 1em 0 2em 0;
      font-weight: 600;
      color: #333;
    }

    >p {
      margin-bottom: 1em;
      font-size: larger;
      text-align: center;
    }

    form {
      align-items: center;

      input[type="submit"] {
        color: #ffffff;
        border-color: #57157D;
        background: #57157D;
        filter: drop-shadow(0px 4px 20px rgba(187, 98, 238, 0.38));
        border-radius: 10px;
        text-transform: uppercase;
        height: 40px;
        width: 90px;
        margin-left: 2em;
      }
    }

    .btn-primary {
      color: #ffffff;
      border-color: #57157D;
      background: #57157D;
      filter: drop-shadow(0px 4px 20px rgba(187, 98, 238, 0.38));
      border-radius: 10px;
      text-transform: uppercase;
      height: 40px;
      width: 130px;
      margin: auto;
    }

    .upload-content {
      margin-bottom: 3em;

      h3 {
        text-align: center;
      }

      label {
        cursor: pointer;
      }

      &--btn {
        margin-top: 20px;
        margin-bottom: 1em;


        >label {
          color: #57157D;
          border: 2px dashed #57157D;
          padding: .5em 2em;
          width: fit-content;
          transition: all .5s ease-in-out;

          &:hover {
            background: #57157D;
            color: #fff;
            border-color: #fff;
          }
        }
      }

      input {
        display: none;
      }

      span {
        cursor: pointer;
        font-size: smaller;
        transition: all ease .5s;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;

        svg {
          opacity: 0;
          width: 16px;
          height: 16px;
          stroke: red;
        }

        &:hover {
          svg {
            opacity: .7;
          }
        }
      }

    }

    &-diagnostic {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 50px;
      margin: 2em 0;
      position: relative;

      >div {
        display: flex;

        >div {
          align-items: center;
          display: block;
          margin-right: 2em;

          span {
            color: #B3B3B3;
            font-size: larger;
          }

          input {
            margin: 0 1em;
          }
        }
      }

      .form-error-msg {
        bottom: -1em;
      }
    }
  }

  .negotiate {
    text-align: center;

    form {
      display: flex;
      align-items: center;
    }
  }

  .date {
    &-list {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      margin-bottom: 2em;

      &-header {
        >p {
          font-size: larger;
        }
      }
    }

    &-badge {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: solid 1px #6B6B6B;
      border-radius: 10px;
      margin-top: 1em;
      margin-right: 2em;
      width: 60%;
      padding: .5em 1em;
      transition: all .7s ease;

      button {
        text-transform: uppercase;
        transition: all .7s ease;
        //background: #FC176F;
        border: 1px solid #FC176F;
        box-shadow: 0px 4px 20px rgba(187, 98, 238, 0.38);
        color: #FC176F;
        border-radius: 10px;
        font-size: smaller;
        margin-left: 5em;
        height: 27px;
        width: 99px;
      }

      &:hover {
        color: #ffffff;
        border-color: #57157D;
        background: #57157D;

        button {
          background: #FC176F;
          border: 1px solid #FC176F;
          color: #ffffff;
        }
      }

      &-list {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
      }
    }

    &-list-acepted {
      .date-badge {
        @include is-disabled;

        &-active {
          color: #ffffff;
          border-color: #57157D;
          background: #57157D;
          opacity: 1;
        }

      }
    }
  }

  &-title {
    color: #00529B;
    background-color: #BDE5F8;
    padding: .5em 2em;
    width: fit-content;
    margin: auto;
    display: flex;
    align-items: center;

    svg {
      font-size: 1.5em;
      margin-right: .5em;
    }
  }

  &-advice {
    color: #00529B;
    background-color: #BDE5F8;
    padding: 1em 2em;
    width: fit-content;
    margin: auto;
    display: flex;
    align-items: center;

    svg {
      font-size: 1.5em;
      margin-right: .5em;
    }
  }

  .upload-content-evidence {
    text-align: center;

    img {
      max-height: 300px;
    }
  }

  &-actions {
    margin-left: auto;
    margin-top: auto;
    width: fit-content;

    button {
      height: 40px;
      padding: 0 2em;
      text-transform: uppercase;
      font-weight: 600;
      border-radius: 10px;
    }

    .btn {
      &-cancel {
        background: transparent;
        border: 1px solid #FC176F;
        color: #FC176F;
        margin-right: 2em;
      }

      &-accept {
        color: #ffffff;
        border: none;
        background: linear-gradient(180deg, #57157D 0%, #7923AA 99.99%, rgba(121, 35, 170, 0) 100%);
        filter: drop-shadow(0px 4px 20px rgba(187, 98, 238, 0.38));
      }
    }
  }
}