$height: calc(100vh - 50px);
$margin: auto;
//Colors//
$color_pink: #d82067;
$color_grey: #dfdfdf;
$color_black: #000000;
$color_text: #65137d;
$color_button: #57157d;

//Wrapper
$max-width-wrapper: 1440px;

.main {
  background: #ffffff;
  display: flex;
  flex-direction: column;

  .main-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .landing-benefits {
      background-color: #61198b;
      width: calc(100% / 2);
      color: #dfdfdf;
      height: $height;
    }

    .main-row {
      width: calc(100% / 2);
      height: $height;
      padding: 0px;
      margin: 0px;
      align-items: center;
      justify-content: center;
      display: flex;

      .main-column {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 770px) {
    .main-content {
      .landing-benefits {
        width: 100%;
        height: 900px;
      }

      .main-row {
        width: 100%;

        .main-column {
          width: 90%;

        }
      }
    }
  }
}
