.sign-up {
  &-user-form {
    .header {
      position: fixed;
      z-index: 1;

      //background: linear-gradient(126.08deg, rgba(98, 113, 246, 0.1) 27.37%, rgba(249, 65, 122, 0.1) 100.36%);
      .menu {
        display: none;
      }

      img {
        margin-top: 1em;
        width: 130px !important;
      }
    }
  }

  &-users {
    width: calc(100%/2);
    height: calc(100vh - 50px);
    padding: 0px;
    margin: 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0 2em;
    overflow: auto;

    .login-sign {
      margin-left: 0;
      margin-bottom: 2em;
      max-width: 400px;

      h1 {
        color: #61198B;
        font-size: x-large;
      }

      p {
        max-width: 330px;
        font-size: medium;
        margin-top: .5em;
      }
    }

    .theme2 {
      overflow-x: hidden;
      width: 100%;
      min-width: 360px;
      border-radius: 5px;
      box-shadow: none;
      background-color: transparent;

      select,
      input[type=text],
      input[type=number],
      input[type=email] {
        max-width: 320px;
        box-shadow: 0px 4px 61px rgba(190, 186, 186, 0.3);
        border-radius: 100px;
        border: none;
        color: #B3B3B3;
        padding: 1em 2em !important;
        margin-bottom: 2em;

        &:focus,
        &:focus-within {
          border: 1px solid #57157D !important;
          color: #57157D;

          &::placeholder {
            color: #57157D;
          }
        }
      }

      .agile-field select {
        border-radius: 100px;
        padding: 10px 2em !important;

        &:not([multiple]) {
          background-position-x: 95% !important;
        }
      }

      button {
        display: block;
        max-width: 320px;
        background: linear-gradient(180deg, #57157D 0%, #7923AA 99.99%, rgba(121, 35, 170, 0) 100%);
        filter: drop-shadow(0px 4px 20px rgba(187, 98, 238, 0.38));
        border-radius: 100px;
        text-transform: uppercase;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: bold;
        height: 40px;
        padding: 0 3em;
        outline: none;
        border: none;
      }

      .agile-label {
        display: none;
      }

      .agile-group {
        margin-bottom: 0;

        &:nth-child(3) {
          padding-top: 0;
        }

        .agile-header-element {
          font-size: larger;
          font-weight: 500;
          color: #57157D;
          margin-bottom: 1em;
        }

        .agile-text-element {
          padding-right: 6em;
        }
      }
    }

    .form {

      &-tenant {
        fieldset {
          display: flex;
          flex-wrap: wrap;

          .agile-group {
            width: 45%;
            margin-left: 20px;

            &:last-child {
              width: 100%;
              text-align: center;

              .agile-button-field {
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }

      &-refered {
        fieldset {
          .agile-field {
            width: 100%;
          }

          >div:nth-last-child(2) {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }

  &-cover {
    width: calc(100%/2);
    color: #DFDFDF;
    height: calc(100vh - 50px);
    background-image: url('https://v2.yampi.co/wp-content/uploads/2020/10/Yampi-Registro-1.jpg');
    background-size: cover;
    position: relative;

    .login-sign {
      margin: auto;
      justify-content: space-between;
      flex-direction: column;
      display: flex;
      position: absolute;
      width: 100%;
      bottom: 10%;
      background: #0000007e;
      padding: 2em;
    }

    .login-sign-user {
      font-size: 25px;
      text-align: right;
      font-weight: bold;
      margin: auto 0;
      font-size: 34px;
      line-height: 1em;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .login-sign-yampi {
      font-style: normal;
      font-weight: normal;
      font-size: 25px;
      line-height: 30px;
      text-align: right;
      color: #FFEFEF;
      margin-top: 1em;
    }

    .login-sign-button {
      max-width: 200px;
      @include flex-center;
      width: 200px;
      text-transform: uppercase;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: bold;
      height: 40px;
      outline: none;
      border: none;
      margin-left: auto;
      background: #FC176F;
      box-shadow: 0px 4px 20px rgba(187, 98, 238, 0.38);
      border-radius: 100px;
      margin-top: 2em;
    }
  }

  &-cover-message {
    width: 100%;
    height: 100%;
    @include flex-center;
    background-color: #000000ab;

    >div {
      h2 {
        font-size: x-large;
        margin: 1em 0;
        max-width: 500px;
      }

      p {
        margin: .4em 0;
      }

      p,
      li {
        font-weight: 600;
      }

      ul {
        margin: 1em 0;

        li {
          margin: .3em 0 .3em 1em;
        }
      }

      a {
        color: #7923AA;
        font-weight: bolder;
        cursor: pointer;
      }

    }
  }

  @media screen and (max-width: $tablet) {
    &-cover {
      opacity: 0;
      height: 2em;
    }

    &-user-form {
      .header {
        position: relative;
      }

      .sign-up-users .form-tenant {
        padding-bottom: 2em;

        fieldset .agile-group {
          width: 100%;
        }
      }
    }

    &-users {
      width: 100%;
      height: 100%;

      .login-sign {
        margin-left: 0;
      }

      .theme2 {
        min-width: auto;

        .agile-group {
          margin-left: 0;
        }
      }
    }
  }
}