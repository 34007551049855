.header {
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;

  //flex
  flex-direction: row;
  flex-wrap: wrap;
  height: 60px;
  width: 100%;
  padding: 0 20px;

  .header__logo {
    cursor: pointer;

    .header__logo_yampi {
      width: 100px;
      vertical-align: top;
    }
  }

  .menu {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    width: 50%;

    >li {
      margin-right: 2em;
    }

    .profile-card {
      @include flex-center;
      margin-right: 2em;

      span {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #c4c4c4;
        margin-right: .5em;
      }

      svg {
        color: #333;
        margin-left: 1em;
        font-size: larger;
      }
    }
  }
}

@media screen and (max-width: $medium) {
  .header {
    padding: 0;

    .menu {
      flex-direction: column;

      >li {
        margin-right: 0;
      }
    }
  }
}